<template>
  <div>
    <v-form>
      <p class="mb-2">
        [A] refers to the <b>reproducibility of a measurement</b> and is quantified by calculating
        the [B] or [C].
      </p>

      <p>
        [D] refers to the <b>closeness of a result to the true value</b> and is quantified by
        calculating the [E].
      </p>

      <p class="mb-1">
        [A]:
        <v-select
          :key="optionsA"
          v-model="inputs.inputA"
          class="d-sm-inline-block ml-3 mr-3"
          :items="optionsA"
          item-text="text"
          item-value="value"
          style="width: 293px"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [B]:
        <v-select
          :key="optionsB"
          v-model="inputs.inputB"
          class="d-sm-inline-block ml-3 mr-3"
          :items="optionsB"
          item-text="text"
          item-value="value"
          style="width: 293px"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [C]:
        <v-select
          :key="optionsC"
          v-model="inputs.inputC"
          class="d-sm-inline-block ml-3 mr-3"
          :items="optionsC"
          item-text="text"
          item-value="value"
          style="width: 293px"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-1">
        [D]:
        <v-select
          :key="optionsD"
          v-model="inputs.inputD"
          class="d-sm-inline-block ml-3 mr-3"
          :items="optionsD"
          item-text="text"
          item-value="value"
          style="width: 293px"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p>
        [E]:
        <v-select
          :key="optionsE"
          v-model="inputs.inputE"
          class="d-sm-inline-block ml-3 mr-3"
          :items="optionsE"
          item-text="text"
          item-value="value"
          style="width: 293px"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI_1LC_Q1S_Q4',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputA: null,
        inputB: null,
        inputC: null,
        inputD: null,
        inputE: null,
      },
      optionsA: [
        {text: 'Precision', value: 'a'},
        {text: 'Accuracy', value: 'b'},
        {text: 'Error', value: 'c'},
        {text: 'Mean', value: 'd'},
        {text: 'Average deviation', value: 'e'},
        {text: 'Standard deviation', value: 'f'},
        {text: 'Percent error', value: 'g'},
      ],
      optionsB: [
        {text: 'precision', value: 'a'},
        {text: 'accuracy', value: 'b'},
        {text: 'error', value: 'c'},
        {text: 'mean', value: 'd'},
        {text: 'average deviation', value: 'e'},
      ],
      optionsC: [
        {text: 'precision', value: 'a'},
        {text: 'accuracy', value: 'b'},
        {text: 'error', value: 'c'},
        {text: 'mean', value: 'd'},
        {text: 'standard deviation', value: 'e'},
        {text: 'percent error', value: 'f'},
      ],
      optionsD: [
        {text: 'Precision', value: 'a'},
        {text: 'Accuracy', value: 'b'},
        {text: 'Error', value: 'c'},
        {text: 'Mean', value: 'd'},
        {text: 'Standard deviation', value: 'e'},
        {text: 'Percent error', value: 'f'},
      ],
      optionsE: [
        {text: 'precision', value: 'a'},
        {text: 'accuracy', value: 'b'},
        {text: 'error', value: 'c'},
        {text: 'standard deviation', value: 'd'},
        {text: 'average deviation', value: 'e'},
        {text: 'percent error', value: 'f'},
      ],
    };
  },
};
</script>
