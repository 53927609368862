var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',[_c('p',{staticClass:"mb-2"},[_vm._v(" [A] refers to the "),_c('b',[_vm._v("reproducibility of a measurement")]),_vm._v(" and is quantified by calculating the [B] or [C]. ")]),_c('p',[_vm._v(" [D] refers to the "),_c('b',[_vm._v("closeness of a result to the true value")]),_vm._v(" and is quantified by calculating the [E]. ")]),_c('p',{staticClass:"mb-1"},[_vm._v(" [A]: "),_c('v-select',{key:_vm.optionsA,staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"293px"},attrs:{"items":_vm.optionsA,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.inputA),callback:function ($$v) {_vm.$set(_vm.inputs, "inputA", $$v)},expression:"inputs.inputA"}})],1),_c('p',{staticClass:"mb-1"},[_vm._v(" [B]: "),_c('v-select',{key:_vm.optionsB,staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"293px"},attrs:{"items":_vm.optionsB,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.inputB),callback:function ($$v) {_vm.$set(_vm.inputs, "inputB", $$v)},expression:"inputs.inputB"}})],1),_c('p',{staticClass:"mb-1"},[_vm._v(" [C]: "),_c('v-select',{key:_vm.optionsC,staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"293px"},attrs:{"items":_vm.optionsC,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.inputC),callback:function ($$v) {_vm.$set(_vm.inputs, "inputC", $$v)},expression:"inputs.inputC"}})],1),_c('p',{staticClass:"mb-1"},[_vm._v(" [D]: "),_c('v-select',{key:_vm.optionsD,staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"293px"},attrs:{"items":_vm.optionsD,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.inputD),callback:function ($$v) {_vm.$set(_vm.inputs, "inputD", $$v)},expression:"inputs.inputD"}})],1),_c('p',[_vm._v(" [E]: "),_c('v-select',{key:_vm.optionsE,staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"293px"},attrs:{"items":_vm.optionsE,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.inputE),callback:function ($$v) {_vm.$set(_vm.inputs, "inputE", $$v)},expression:"inputs.inputE"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }